import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {SmartTableColSpec} from '../table/smart-table.component';
import {Observable} from 'rxjs';
import {debounceTime, take, takeUntil, tap} from 'rxjs/operators';
import {Filter} from '../../../../@core/filter';
import {Paginated} from '../../../../@core/api.service';
import {TableBaseComponent} from '../table-base/table-base.component';
import {RequestHandler} from '../../../../@core/utils/request-handler';
import {extractErrorMessage} from '../../../../utils/error.utils';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-paginated-table',
    templateUrl: './paginated-table.component.html',
    styleUrls: ['../table/smart-table.component.scss'],

})
export class PaginatedTableComponent<T extends {id?: number; selected?: boolean} = any> extends TableBaseComponent<T> implements OnChanges, OnInit {
    @Input() apiFunction: (params: any) => Observable<Paginated<any>>;
    @Input() requestHandler: RequestHandler<any, any>;
    @Input() showPagination = true;
    @Input() showNoData = true;
    @Input() page = 1;
    @Input() pageSize = 20;
    @Input() filters: Filter;
    @Input() filterOnChange = true;
    @Input() validateFilters: (filters) => boolean;
    @Input() ordering: string;
    @Input() orderingDirection: 'asc' | 'desc';
    @Input() processFn: (data: any[]) => any[];
    @Input() fullDownload = false;

    @Output() filtersChange = new EventEmitter<any>();
    @Output() orderingChange = new EventEmitter<string>();
    @Output() pageChange = new EventEmitter<number>();
    @Output() orderingDirectionChange = new EventEmitter<'asc' | 'desc'>();
    @Output() itemCount = new EventEmitter<number>();

    @Output() responseChange = new EventEmitter<any>();

    @ViewChild('tableTmp', {static: false}) tableTmp: ElementRef;

    count: number;
    loading = true;
    errorMsg: string;
    error: HttpErrorResponse;

    ngOnInit() {
        this.filters.updates$.pipe((this.filterOnChange ? tap() : take(1)), takeUntil(this.destroy$), debounceTime(300)).subscribe(x => this.getData(x?.page));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.pageSize && changes.pageSize.currentValue !== this.pageSize) {
            this.getData();
        }

        if (changes.validateFilters &&
            !changes.validateFilters.firstChange &&
            changes.validateFilters.currentValue &&
            changes.validateFilters.previousValue !== changes.validateFilters.currentValue) {
            this.getData();
        }

        super.ngOnChanges(changes);
    }

    getData(page = 1, printingFull?: boolean) {
        if (this.validateFilters && !this.validateFilters(this.filters.params)) return;
        this.cancelRequest$.next();
        this.page = page;
        this.data = [];
        this.processSelected();
        this.orderingDirection = this.orderingDirection ?? (this.ordering?.trim()[0] === '-' ? 'desc' : 'asc');
        this.sorting = {
            col: this.definition.cols.find(col => (this.ordering?.trim()[0] === '-' ? this.ordering?.substring(1) : this.ordering) === col.sortKey),
            desc: this.orderingDirection === 'desc',
        };

        const request = this.apiFunction ?? (args => this.requestHandler.call(args));
        if (request) {
            this.loading = true;
            request({
                ...this.filters.params,
                page: printingFull ? null : this.page,
                page_size: printingFull ? null : this.pageSize,
                ordering: this.ordering ? `${this.orderingDirection === 'desc' ? '-' : ''}${this.ordering?.trim()[0] === '-' ? this.ordering?.substring(1) : this.ordering}` : null,
            }).pipe(takeUntil(this.cancelRequest$)).subscribe(data => {
                this._processResponse(data);
            }, error => {
                this.errorMsg = extractErrorMessage(error);
                this.error = error;
                this.loading = false;
            }, () => {
                if (printingFull) {
                    setTimeout(() => {
                        this.onPrint(true);
                        this.getData(0);
                    }, 1000);
                }
            });
        }
    }

    onSortSelect(col: SmartTableColSpec<T>) {
        if (!col.sortKey) return;
        if (this.ordering !== col.sortKey) {
            this.ordering = col.sortKey;
            this.orderingDirection = 'asc';
        } else {
            this.orderingDirection = this.orderingDirection === 'asc' ? 'desc' : 'asc';
        }
        this.orderingDirectionChange.emit(this.orderingDirection);
        this.orderingChange.emit(this.ordering);
        this.pageChange.emit(1);
        this.getData();
    }

    onPageChange(page: number) {
        this.pageChange.emit(page);
        this.getData(page);
    }

    onPrint(downloadingFull = false) {
        if (!this.fullDownload || downloadingFull) {
            if (!downloadingFull) {
                super.onPrint();
            } else {
                this.printService.printElement(this.tableTmp);
            }
            return;
        }
        this.getData(0, true);
    }

    private _processResponse(data: Paginated<any>) {
        this.data = this.processFn ? this.processFn(data.results) : data.results;
        this.count = data.count;
        this.itemCount.emit(this.count);
        this.responseChange.emit(data);
        this.processSelected();
        this.loading = false;
    }
}
