<div *ngIf="downloadEnabled && data?.length" ngbDropdown placement="bottom-right" class="d-flex more-actions">
    <button class="action-icon sm"
            ngbDropdownToggle
            [attr.aria-label]="'Download or print files'">
        <far i="ellipsis-v"></far>
    </button>
    <div ngbDropdownMenu>
        <button (click)="onDownload('XLSX')"
                class="dropdown-item"
                [attr.aria-label]="'Download files as XLSX'">
            <fal i="download" class="icon"></fal>
            Download As .XLSX
        </button>
        <button (click)="onDownload('CSV')"
                class="dropdown-item"
                [attr.aria-label]="'Download files as CSV'">
            <fal i="download" class="icon"></fal>
            Download As .CSV
        </button>
        <button (click)="onPrint()"
                class="dropdown-item"
                [attr.aria-label]="'Print files'">
            <fal i="print" class="icon"></fal>
            Print
        </button>
    </div>
</div>

<ng-container *ngIf="apiFunction; else requestHandlerTable">
    <ng-container *ngTemplateOutlet="table; context: {hasLoading: true}"></ng-container>
</ng-container>
<ng-template #requestHandlerTable>
    <ng-container *appRequestHandler="requestHandler">
        <ng-container *ngTemplateOutlet="table; context: {hasLoading: false}"></ng-container>
    </ng-container>
</ng-template>

<ng-template #table let-hasLoading="hasLoading">
    <ng-container *ngIf="((hasLoading && !loading) || !hasLoading) && data?.length">
        <div #tableTmp [class.table-responsive]="hasScrollContainer">
            <table class="table table-sm small {{tableClasses}}" [class.table-hover]="definition.rowAction || reviewType">
                <thead>
                    <tr smart-table-header
                        [data]="data"
                        [definition]="definition"
                        [reviewType]="reviewType"
                        [selectable]="selectable"
                        [hasSorting]="hasSorting"
                        [sorting]="sorting"
                        [insertTemplateCols]="insertTemplateCols"
                        [shouldShowDetails]="showDetail"
                        [allRowsSelected]="allRowsSelected"
                        [paginated]="true"
                        (sortSelect)="onSortSelect($event)"
                        (selectAll)="selectAll($event)">
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!extraRowTemplate; else trWithExtraRow">
                        <ng-container *ngFor="let item of data">
                            <ng-container *ngTemplateOutlet="tr; context: {item:item}"></ng-container>
                        </ng-container>
                    </ng-container>
                <ng-template #trWithExtraRow>
                    <ng-container *ngFor="let item of data">
                        <ng-container *ngTemplateOutlet="tr; context: {item:item}"></ng-container>
                        <ng-container *ngTemplateOutlet="extraRowTemplate; context: {item:item}"></ng-container>
                    </ng-container>
                </ng-template>
                <ng-template #tr let-item="item">
                    <tr smart-table-row
                        [definition]="definition"
                        [reviewType]="reviewType"
                        [item]="item"
                        [tableData]="data"
                        [selectable]="selectable"
                        [colTemplates]="colTemplates"
                        [insertTemplateCols]="insertTemplateCols"
                        [shouldShowDetails]="showDetail"
                        (openSlidePanel)="onOpenSlidePanel($event)"
                        (selectRow)="selectRow($event)"
                        [attr.data-test-id]="definition?.title">
                    </tr>
                </ng-template>
                </tbody>
            </table>
        </div>
    </ng-container>

    <app-pagination *ngIf="showPagination && data?.length"
                    class="mt-3"
                    [hidden]="loading"
                    [count]="count"
                    [pageSize]="pageSize"
                    [currentPage]="page"
                    (pageChanged)="onPageChange($event)">
    </app-pagination>

    <app-spinner *ngIf="loading"></app-spinner>

    <div *ngIf="!loading && !data?.length && !errorMsg && showNoData"
         class="text-muted">
        No data to show.
    </div>

    <app-error-message *ngIf="!loading && !data?.length && errorMsg"
                       [errorMsg]="errorMsg"
                       [error]="error">
    </app-error-message>
</ng-template>
